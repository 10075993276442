import React from 'react';
import NewGraphNavigator from './DevGraphNavigator/NewGraphNavigator';

const DevAethericAI = () => {
  // Use conceptName as the ID, or pass a separate graphId prop.
  // e.g. "AethericAI" or "Graph-AethericAI"
  return (
    <div className="flex justify-center items-center bg-black flex-grow">
      <NewGraphNavigator
        // initialNodeId="a0de03b3-b027-43e5-a411-0ccfe7820613"
        initialNodeId="a0de03b3-b027-43e5-a411-0ccfe7820613"
        graphId="Dev_Graph-AethericAI" 
      />
    </div>
  );
};

export default DevAethericAI;