// src/components/AethericAI/DevGraphNavigator/NewGraphNavigator.js

import React, { useState, useEffect, useRef, useContext } from "react";
import SparkMD5 from "spark-md5";
import { v4 as uuidv4 } from "uuid";

import AuthContext from "../../../contexts/AuthContext";  
import { InitialRootProvider } from "../../../contexts/InitialRootContext";
import { useLocalStorageGraphState } from "./useLocalStorageGraphState";

// Hooks (graph data, d3 rendering, node events, etc.)
import { useGraphData } from "./hook/useGraphData"; 
import { useD3Graph } from "./hook/useD3Graph";
import useHandleNodeEvents from "./hook/handleNodeEvents";
import useHandleNodeCreation from "./hook/handleNodeCreation";
import useHandleRelationshipCreation from "./hook/handleRelationshipCreation";
import useHandleNodeUpdate from "./hook/useHandleNodeUpdate";
import useHandleNodeDelete from "./hook/useHandleNodeDelete";
import useHandleRelationshipDelete from "./hook/useHandleRelationshipDelete"; 
import useHandleGetNodeAndRelationships from "./hook/useHandleGetNodeAndRelationships";


// UI Components
import { ActionPanel } from "./components/ActionPanel";
import AiFooter from "./components/AiFooter";
import AiHeader from "./components/AiHeader";
import NodeCreationForm from "./components/NodeCreationForm";
import UpdateNodeForm from "./components/UpdateNodeForm";



/**
 * NewGraphNavigator
 * 
 * This component orchestrates:
 *  1) Local state for “selected nodes,” pinned positions, etc.
 *  2) Loading/saving that state from localStorage.
 *  3) Rendering the graph via `useD3Graph`.
 *  4) Handling node creation/editing/deletion via modals and custom hooks.
 *  5) Integrating an AI header and footer for additional user interactions.
 */
const NewGraphNavigator = ({ initialNodeId, graphId }) => {

  /*******************************************************************
   * 1) CONTEXT & USER INFO
   *******************************************************************/
  const { user } = useContext(AuthContext);

  // If graphId is missing, fall back to conceptName or generate a UUID
  const fallbackGraphId = graphId || uuidv4();

  const userIdPart = user?.id || "guest";

  const storageKey = `graphState-${userIdPart}-${fallbackGraphId}`;

  /*******************************************************************
   * AI MESSAGES & HEADER STATUS
   *******************************************************************/
  // For AiHeader & AiFooter components
  const [aiMessages, setAiMessages]       = useState([]);
  const [isAiHeaderOpen, setIsAiHeaderOpen] = useState(false);


  // -----------------------
  // 1) State to store fetched initial node from DB
  // -----------------------
  const [initialNode, setInitialNode] = useState(null);

  // 2) Use your DB fetch hook
  const {
    fetchNodeAndRelationships,
    fetchData: fetchData,
    error: fetchError,
    loading: fetchLoading,
  } = useHandleGetNodeAndRelationships({
          setAiMessages,
          setIsAiHeaderOpen
        }
    );

  // 3) On mount (or whenever initialNodeId changes), do the fetch
  useEffect(() => {
    if (!initialNodeId) return;
    console.log("Fetching initial node:", initialNodeId);
    fetchNodeAndRelationships({ id: initialNodeId, depth: 1 });
  }, [initialNodeId, fetchNodeAndRelationships]);

  // 4) Once data arrives, set `initialNode` to the DB node
  useEffect(() => {
    if (!fetchData?.getNodeAndRelationships) return;
    const fetchedNodes = fetchData.getNodeAndRelationships.nodes;
    if (!fetchedNodes || !fetchedNodes.length) {
      console.warn("[NewGraphNavigator] No node found for initialNodeId:", initialNodeId);
      return;
    }
    // e.g. pick the first node as your new initial node
    setInitialNode(fetchedNodes[0]);
  }, [fetchData, initialNodeId]);

  console.log("initialNode", initialNode);

  useEffect(() => {
    console.log("[DEBUG] initialNode changed:", initialNode);
  }, [initialNode]);


  const {
      selectedNodes,
      setSelectedNodes,
      selectedNodesRef,
      pinnedPositions,
      setPinnedPositions,
      actionPanelOpen,
      setActionPanelOpen,
      actionPanelOpenRef,
      rootNode,
      setRootNode,
      panelOpenNode,
      setPanelOpenNode,
      panelOpenNodeRef,
      selectedNodesKey,
      pinnedPositionsKey,
      rootNodeKey,
      handleSetRoot,
    } = useLocalStorageGraphState(user, initialNode, storageKey, initialNodeId);

  // Build user-specific localStorage keys
  // Example usage: storing pinnedPositions or selectedNodes for each user
  // const initialRootNodeRef = useRef(rootNode);
  const [didLoadFromStorage, setDidLoadFromStorage] = useState(false);



  /*******************************************************************
   * 7) FORMS / MODALS
   *******************************************************************/
  // Track if NodeCreationForm or UpdateNodeForm are open
  const [editNodeModalOpen, setEditNodeModalOpen] = useState(false);
  const [nodeToEdit, setNodeToEdit]               = useState(null);

  const [createNodeModalOpen, setCreateNodeModalOpen]   = useState(false);
  const [originNodeForCreation, setOriginNodeForCreation] = useState(null);

  /*******************************************************************
   * 8) NODE EVENT HANDLING
   *******************************************************************/
  // For expansions (e.g., double-click expansions in the graph)
  const originNodeIdRef = useRef(null);
  // Relationship-creation state
  const [relationshipSourceId, setRelationshipSourceId] = useState(null);
  const relationshipSourceRef = useRef(null);


  /*******************************************************************
   * 9) MISCELLANEOUS
   *******************************************************************/
  // Example: For detecting repeated creation calls
  const [lastCreateKey, setLastCreateKey] = useState(null);

  /**************************************************************************
   * PINNED POSITIONS (LOCALSTORAGE)
   **************************************************************************/

  // 2) Save pinned positions to localStorage whenever they change
  useEffect(() => {
    if (!pinnedPositionsKey) return;
    localStorage.setItem(pinnedPositionsKey, JSON.stringify(pinnedPositions));
  }, [pinnedPositions, pinnedPositionsKey]);

  /*******************************************************************
   * 3) ACTION PANEL
   *******************************************************************/
  useEffect(() => {
    actionPanelOpenRef.current = actionPanelOpen;
  }, [actionPanelOpen]);

  /*******************************************************************
   * 4) AI MESSAGES (AiHeader / AiFooter)
   *******************************************************************/
  // Simple callback for AI responses
  const handleAiResponse = (aiData) => {
    const { userOutput } = aiData || {};
    if (!userOutput) return;
    // Avoid duplicates
    setAiMessages((prev) => {
      if (prev.length > 0 && prev[prev.length - 1] === userOutput) {
        return prev;
      }
      return [...prev, userOutput];
    });
    setIsAiHeaderOpen(true);
  };

  /*******************************************************************
   * 5) MODALS: NODE CREATION / UPDATE
   *******************************************************************/

  // Called when user wants to open the “Edit Node” modal
  const onOpenEditModal = (node) => {
    setNodeToEdit(node);
    setEditNodeModalOpen(true);
  };

  // Called when user wants to open the “Create Node” modal
  const onOpenCreateModal = (originNode) => {
    setOriginNodeForCreation(originNode);
    setCreateNodeModalOpen(true);
  };


  /*******************************************************************
   * 6) CUSTOM HOOKS FOR NODE EVENTS & GRAPH DATA
   *******************************************************************/
  const { handleCreateRelationship, relData, relError }
  = useHandleRelationshipCreation();
  
  const {
    handleNodeClick,
    handleNodeDoubleClick,
    queryData,
    queryError,
  } = useHandleNodeEvents({
    userRole:        user?.role,
    setActionPanelOpen,
    setPanelOpenNode,
    setSelectedNodes,
    selectedNodesRef,
    panelOpenNodeRef,
    actionPanelOpenRef,
    originNodeIdRef,
    // setSelectedNodeIds, // if you needed them
    setAiMessages,
    setIsAiHeaderOpen,
    relationshipSourceId,
    setRelationshipSourceId,
    handleCreateRelationship,
    relationshipSourceRef,
  });

  // (B) useGraphData: manages the local graph data structure, merges queries
  const {
    graphData,
    insertNodesAndLinks,
    updateNodesAndLinks,
    deleteNodesAndLinks,
    resetGraphData,
  } = useGraphData({
    initialNode:   rootNode,
    data:          queryData,
    originNodeIdRef,
    user,
    storageKey,
  });

  // Keep pinned positions in sync with the local D3 data
  useEffect(() => {
    if (!graphData?.nodes) return;
    graphData.nodes.forEach((node) => {
      const isSelected = selectedNodes.some((sel) => sel.id === node.id);
      const pinned = pinnedPositions[node.id];
      // Pin if selected & pinnedPositions exist, else unpin
      if (isSelected && pinned) {
        node.fx = pinned.fx;
        node.fy = pinned.fy;
      } else {
        node.fx = null;
        node.fy = null;
      }
    });
  }, [graphData, selectedNodes, pinnedPositions]);


  /*******************************************************************
   * 7) HOOKS FOR CREATING / UPDATING / DELETING NODES
   *******************************************************************/
  // Creation
  const { handleCreateNode, createNodeData, createNodeError } 
    = useHandleNodeCreation();


  // Update
  const { handleUpdateNode, updateNodeData, updateNodeError }
    = useHandleNodeUpdate();

  // Delete
  const { handleDeleteNode, deleteNodeData, deleteNodeError }
    = useHandleNodeDelete();
      const {
        handleDeleteRelationship,
        deleteRelationshipData,
        deleteRelationshipError,
        deleteRelationshipLoading,
      } = useHandleRelationshipDelete();

  /*******************************************************************
   * 8) D3 Graph Rendering (useD3Graph)
   *******************************************************************/

  // Measure the actual size of the <svg> in real time
  // const { width, height } = useElementSize(svgRef);

  // Renders the graph in an <svg>, sets up simulation, etc.
  const {
    svgRef,
    zoomToNode,
    height,
    width,
  } = useD3Graph({
    user,
    graphData,
    // initialNode: initialRootNodeRef.current,
    initialNode: rootNode,
    rootNode,
    selectedNodes,
    panelOpenNodeId: panelOpenNode ? panelOpenNode.id : null,
    onNodeClick:       handleNodeClick,
    onNodeDoubleClick: handleNodeDoubleClick,
    pinnedPositions,
    setPinnedPositions,

    // ephemeral node creation/editing from the ephemeral icons
    handleCreateNode,
    handleUpdateNode,
    handleDeleteNode,
    onOpenEditModal,
    onOpenCreateModal,
    originNodeIdRef,
    relationshipSourceId,
    setRelationshipSourceId,
    handleDeleteRelationship,
    relationshipSourceRef,
  });


  /**********************************
   * 10) MERGE EFFECTS (Query, etc.)
   **********************************/
  // A) Insert fetched nodes
  useEffect(() => {
    if (!queryData?.getNodeAndRelationships) return;
    const fetchedNodes = queryData.getNodeAndRelationships.nodes.map((n) => ({ ...n }));
    const fetchedLinks = queryData.getNodeAndRelationships.relationships.map((r) => ({ ...r }));
    insertNodesAndLinks(fetchedNodes, fetchedLinks, originNodeIdRef.current);
  }, [queryData, insertNodesAndLinks]);

  // B) Node creation
  useEffect(() => {
    if (!createNodeData?.createNode) return;
    const { nodes = [], relationships = [] } = createNodeData.createNode;
    if (!nodes.length) return;

    const nodeIdsKey = nodes.map((n) => n.id).sort().join("-");
    if (nodeIdsKey === lastCreateKey) return;
    setLastCreateKey(nodeIdsKey);

    // Convert shape
    const processed = nodes.map((n) => ({
      id: String(n.id),
      name: n.name || "Unnamed Node",
      label: n.label,
      description: n.description,
      tags: n.tags || [],
      privacyStatus: n.privacy_status,
      status: n.status ,
      hopLevel: 1, 
    }));
    insertNodesAndLinks(processed, relationships, originNodeIdRef.current);

    if (processed.length) {
      const created = processed[0];
      setPanelOpenNode(created);
      setActionPanelOpen(true);
      zoomToNode(created.id, 1.01);
    }
  }, [createNodeData, lastCreateKey, insertNodesAndLinks, originNodeIdRef, setPanelOpenNode, setActionPanelOpen, zoomToNode]);

  // C) Relationship creation
  useEffect(() => {
    if (!relData?.createRelationship) return;
    insertNodesAndLinks([], [relData.createRelationship]);
  }, [relData, insertNodesAndLinks]);

  // D) Node updating
  const [lastUpdateKey, setLastUpdateKey] = useState(null);
  useEffect(() => {
    if (!updateNodeData?.updateNode) return;
    const { nodes = [], relationships = [] } = updateNodeData.updateNode;
    if (!nodes.length) return;

    // Build a hash
    const nodeHashString = nodes
      .map((n) => {
        const sortedTags = (n.tags || []).sort().join(",");
        return [n.id, n.name || "", n.description || "", n.status || "", n.privacy_status || "", sortedTags].join(":");
      })
      .sort()
      .join("|");
    const nodeHash = SparkMD5.hash(nodeHashString);
    if (nodeHash === lastUpdateKey) return;
    setLastUpdateKey(nodeHash);

    // Convert
    const processed = nodes.map((n) => ({
      id: String(n.id),
      name: n.name,
      label: n.label,
      description: n.description,
      tags: n.tags || [],
      privacy_status: n.privacy_status || "PRIVATE",
      status: n.status || "PENDING_VALIDATION",
    }));
    updateNodesAndLinks(processed, relationships);

    if (processed.length) {
      const updated = processed[0];
      setPanelOpenNode(updated);
      setActionPanelOpen(true);
      zoomToNode(updated.id, 1.01);
    }
  }, [updateNodeData, lastUpdateKey, updateNodesAndLinks, setPanelOpenNode, setActionPanelOpen, zoomToNode]);

  // E) Node deletion
  useEffect(() => {
    if (!deleteNodeData?.deleteNode) return;
    const { success, message, deletedId } = deleteNodeData.deleteNode;
    if (success && deletedId) {
      deleteNodesAndLinks([deletedId]);
      if (panelOpenNode?.id === deletedId) {
        setPanelOpenNode(null);
        setActionPanelOpen(false);
      }
    } else {
      console.warn("Deletion refused:", message);
    }
  }, [deleteNodeData, deleteNodesAndLinks, panelOpenNode, setPanelOpenNode, setActionPanelOpen]);

  // F) Relationship deletion
  useEffect(() => {
    if (!deleteRelationshipData?.deleteRelationship) return;
    const { success, message, deletedId } = deleteRelationshipData.deleteRelationship;
    if (success && deletedId) {
      // Now remove that relationship from local graphData
      deleteNodesAndLinks([], [deletedId]);
      // If you had a "panelOpenRelationship", close it here
    } else {
      console.warn("Deletion refused:", message);
    }
  }, [deleteRelationshipData, deleteNodesAndLinks]);



  /*******************************************************************
   * 10) DEBUG & ERROR LOGGING
   *******************************************************************/
  // Zoom in if a panel node is open
  // useEffect(() => {
  //   if (panelOpenNode) {
  //     zoomToNode(panelOpenNode.id, 1.01);
  //   }
  // }, [panelOpenNode, zoomToNode]);

  // Log any GraphQL or creation/update errors
  const { 
    //queryError, 
    //relError, 
    // createNodeError, 
    // updateNodeError 
  } = { /* destructured above or combined for reference */ };

  useEffect(() => {
    if (queryError)       console.error("Query error:", queryError);
    if (createNodeError)  console.error("Create node error:", createNodeError);
    if (relError)         console.error("Create relationship error:", relError);
    if (updateNodeError)  console.error("Update node error:", updateNodeError);
  }, [queryError, createNodeError, relError, updateNodeError]);


  /*******************************************************************
   * 11) RENDER
   *******************************************************************/
  return (
    // <InitialRootProvider initialRoot={initialRootNodeRef.current}>
    <InitialRootProvider initialRoot={rootNode}>
      <div style={{ width: "100%", height: "100vh", position: "relative" }}>

        {/* AI Header (top) */}
        {(user?.role === "ADMIN" || user?.role === "EDITOR") && (
        <div className="fixed top-16 left-0 right-0 z-30">
          <AiHeader
            messages={aiMessages}
            open={isAiHeaderOpen}
            onClose={() => setIsAiHeaderOpen(false)}
          />
        </div>
        )}

        {/* Admin-only clear cache button */}
        {/* {user?.role === "ADMIN" && ( */}
        <button
          onClick={() => {
            // 1) Clear local graph + localStorage
            resetGraphData();
            setSelectedNodes([]);
            if (selectedNodesKey)   localStorage.removeItem(selectedNodesKey);
            if (pinnedPositionsKey) localStorage.removeItem(pinnedPositionsKey);
            if (rootNodeKey)        localStorage.removeItem(rootNodeKey);
            setPinnedPositions({});

            // 2) Zoom to your root node => small delay so the new graph is rendered
            setTimeout(() => {
              zoomToNode(rootNode.id, 1.5);
            }, 50);
          }}
          title="Clear locally saved graph data"
          className="
            fixed
            bottom-14
            right-4
            z-40
            flex items-center justify-center
            w-8 h-8
            bg-gray-800 hover:bg-gray-700 text-white
            rounded-full
            cursor-pointer
          "
        >
          {/* Refresh icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 4v6h6M20 20v-6h-6M9 14l3 3 3-3M15 10l-3-3-3 3"
            />
          </svg>
        </button>
         {/* )} */}

        {/* Button to open AiHeader if closed */}
        {!isAiHeaderOpen && aiMessages.length > 0 && (
          <button
            onClick={() => setIsAiHeaderOpen(true)}
            className="
              fixed
              top-16
              left-0
              bg-black
              text-white
              p-3
              overflow-y-auto
              max-h-20
              z-40
            "
            title="Open AI Messages"
          >
            &gt;
          </button>
        )}

        {/* Optional ActionPanel (commented out by default) */}
        {/* 
          {actionPanelOpen && canOpenPanel && (
            <div className="absolute z-40">
              <ActionPanel
                selectedNodes={selectedNodes}
                setSelectedNodes={setSelectedNodes}
                onSetRoot={handleSetRoot}
                rootNode={rootNode}
                panelOpenNode={panelOpenNode}
                setPanelOpenNode={setPanelOpenNode}
                actionPanelOpen={actionPanelOpen}
                setActionPanelOpen={setActionPanelOpen}
                handleCreateNode={handleCreateNode}
                handleCreateRelationship={handleCreateRelationship}
                handleUpdateNode={handleUpdateNode}
                handleDeleteNode={handleDeleteNode}
              />
            </div>
          )}
        */}

        {/* Main <svg> container where D3 draws the graph */}
        <svg ref={svgRef} style={{ width: "100%", height: "100%" }} />

        {/* Modal: Update Node */}
        {editNodeModalOpen && nodeToEdit && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-60 flex justify-center items-center">
            <div className="bg-gray-800 p-4 rounded">
              <UpdateNodeForm
                currentNode={nodeToEdit}
                onCancel={() => {
                  setEditNodeModalOpen(false);
                  setNodeToEdit(null);
                }}
                onSubmitUpdate={handleUpdateNode}
              />
            </div>
          </div>
        )}

        {/* Modal: Create Node */}
        {createNodeModalOpen && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-60 flex justify-center items-center">
            <div className="bg-gray-800 p-4 rounded">
              <NodeCreationForm
                originNode={originNodeForCreation}
                onCancel={() => setCreateNodeModalOpen(false)}
                onCreate={() => setCreateNodeModalOpen(false)}
                onSubmitNode={handleCreateNode}
                handleCreateRelationship={handleCreateRelationship}
              />
            </div>
          </div>
        )}

        {/* AI Footer (pinned to bottom) */}
        {(user?.role === "ADMIN" || user?.role === "EDITOR") && (
        <div className="fixed bottom-0 left-0 right-0 z-10">
          <AiFooter
            user={user}
            selectedNodes={selectedNodes}
            onAiResponse={handleAiResponse}
            handleSelectNode={(node) => {
              // e.g. open panel for node
            }}
            handleRemoveNode={(node) => {
              // remove node from selection
              setSelectedNodes((prev) => prev.filter((n) => n.id !== node.id));

              // if that node was open, close panel
              if (panelOpenNode?.id === node.id) {
                setPanelOpenNode(null);
                setActionPanelOpen(false);
              }
            }}
            zoomToNode={zoomToNode}
          />
        </div>
        )}

        {/* Tooltip if using D3 mouseover logic */}
        <div
          id="tooltip"
          className="
            pointer-events-none
            absolute
            z-50
            bg-black
            text-white
            px-3 py-2
            rounded
            shadow
            opacity-0
            transition-opacity
            duration-300
          "
          style={{ top: 0, left: 0 }}
        />
      </div>
    </InitialRootProvider>
  );
};

export default NewGraphNavigator;