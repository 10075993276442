import React, { useContext, useEffect } from 'react';
import AuthContext from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

import NewGraphNavigator from '../AethericAI/GraphNavigator/NewGraphNavigator';
  const Dashboard = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
      if (!user) {
        // If user is not authenticated, redirect to login page
        navigate('/');
      }
    }, [user, navigate]);

    if (!user) {
      return null; // Or a loading indicator
    }

    return (
      <div className="flex justify-center items-center bg-black flex-grow">
      <NewGraphNavigator
        // initialNodeId="a0de03b3-b027-43e5-a411-0ccfe7820613"
        initialNodeId={user.id}
        graphId="Dashboard_Graph" 
      />
    </div>
    );
};

export default Dashboard;

