import * as d3 from "d3";
import { getNodeWeight } from "./utils/getNodeWeight";

export function initializeOrUpdateSimulation({
  simulationRef,
  nodes,
  links,
  rootId,
  initialNodeId,
  previousRootIdRef,
  ticked,
  measuredWidth,
  measuredHeight,
  zoomToNodeCallback
}) {
  /********************************************
   * 1) CUSTOMIZE THE LINK FORCE
   ********************************************/
  // We set smaller strength & distance for link.type === "CREATED_BY"
  const linkForce = d3
    .forceLink(links)
    .id((d) => d.id)
    .distance((link) => {
      if (link.type === "CREATED_BY") {
        // e.g. keep them closer
        return 0;
      }
      // default distance
      return 40;
    })
    .strength((link) => {
      if (link.type === "CREATED_BY") {
        // very weak pull
        return 0.005;
      }
      // default strength
      return 0.09;
    });

  if (!simulationRef.current) {
    simulationRef.current = d3
      .forceSimulation(nodes)
      // use our custom link force
      .force("link", linkForce)
      /********************************************
       * 2) CUSTOMIZE THE CHARGE FORCE
       ********************************************/
      .force(
        "charge",
        d3.forceManyBody().strength((node) => {
          // For a "User" node, reduce the repulsion so it doesn't push away too much
          if (node.label === "User") {
            return 0; // e.g. minimal repulsion
          }
          // Otherwise, your default
          return -200;
        })
      )
      // existing collision code
      .force(
        "collision",
        d3.forceCollide().radius((d) => {
          const w = getNodeWeight(d);
          if (d.id === initialNodeId) return 100;
          return d.id === rootId ? 100 : 75;
        })
      )
      // alphaDecay & alphaTarget
      .alphaDecay(0.1)
      .alphaTarget(0.3)
      .on("tick", ticked);

    // Optionally zoom to initial node
    setTimeout(() => {
      zoomToNodeCallback(initialNodeId, 1.5);
    }, 50);

  } else {
    // If the simulation already exists, just update nodes & links
    simulationRef.current.nodes(nodes);
    // Reapply the link force so it has the new config & new link array
    simulationRef.current.force("link", linkForce);
    simulationRef.current.alpha(0.3).restart();
  }

  // Pin the initialNode at center
  const initialNode = nodes.find((n) => n.id === initialNodeId);
  if (initialNode) {
    initialNode.fx = measuredWidth / 2;
    initialNode.fy = measuredHeight / 2;
  }

  // Handle root changes
  const previousRootId = previousRootIdRef.current;
  if (previousRootId !== rootId) {
    // Release the old root if not the initial
    if (previousRootId && previousRootId !== initialNodeId) {
      const oldRootNode = nodes.find((n) => n.id === previousRootId);
      if (oldRootNode) {
        oldRootNode.fx = null;
        oldRootNode.fy = null;
      }
    }
    // Fix the new root where it currently is
    const newRootNode = nodes.find((n) => n.id === rootId);
    if (newRootNode) {
      newRootNode.fx = newRootNode.x;
      newRootNode.fy = newRootNode.y;
    }
    previousRootIdRef.current = rootId;
  } else {
    // If root hasn't changed, just ensure it's still fixed
    const currentRootNode = nodes.find((n) => n.id === rootId);
    if (currentRootNode && currentRootNode.id !== initialNodeId) {
      currentRootNode.fx = currentRootNode.fx ?? currentRootNode.x;
      currentRootNode.fy = currentRootNode.fy ?? currentRootNode.y;
    }
  }
}