// src/components/AethericAI/DevGraphNavigator/hook/useHandleGetNodeAndRelationships.js

import { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_NODE_AND_RELATIONSHIPS } from "../../../../graphql/Graph/graph_queries";

/**
 * A more general “fetch node + relationships” hook.
 * 
 * You can call `fetchNodeAndRelationships({ id, name, depth, originNodeId })`
 * anywhere (e.g. in NewGraphNavigator, or in a modal), 
 * and this will do the same GraphQL query your handleNodeEvents does, 
 * returning the data.
 * 
 * 
 * Usage in a component:
 *   const {
 *     fetchNodeAndRelationships,
 *     data: nodeData,
 *     error: nodeError,
 *     loading: nodeLoading
 *   } = useHandleGetNodeAndRelationships({ setAiMessages, setIsAiHeaderOpen });
 * 
 *   // Then call:
 *   fetchNodeAndRelationships({ id: someId, depth: 1, originNodeId: someId });
 * 
 *   // Once data arrives in nodeData, you can do:
 *   // insertNodesAndLinks(nodeData.getNodeAndRelationships.nodes, nodeData.getNodeAndRelationships.relationships, someId);
 * 
 */
export default function useHandleGetNodeAndRelationships({
  setAiMessages,
  setIsAiHeaderOpen,
}) {
  // 1) Apollo lazy query
  const [
    getNodeAndRelationships,
    { data, error, loading },
  ] = useLazyQuery(GET_NODE_AND_RELATIONSHIPS, {
    fetchPolicy: "network-only", // or "cache-first" if you prefer
  });

  // 2) A small “retry” loop if you like
  const fetchWithRetry = useCallback(
    async (variables, retries = 2) => {
      try {
        const result = await getNodeAndRelationships({ variables });

        // if the server returned “errors” in result, handle them
        if (result?.errors?.length) {
          const firstErr = result.errors[0].message || "Unknown error";
          console.warn("GraphQL returned errors:", result.errors);

          if (retries > 0) {
            // optional delay
            await new Promise((resolve) => setTimeout(resolve, 2000));
            return fetchWithRetry(variables, retries - 1);
          } else {
            setAiMessages?.((prev) => [...prev, `Failed: ${firstErr}`]);
            setIsAiHeaderOpen?.(true);
            return false;
          }
        }

        return true;
      } catch (err) {
        console.error("[useHandleGetNodeAndRelationships] Network error:", err);
        if (retries > 0) {
          // optional delay
          await new Promise((resolve) => setTimeout(resolve, 2000));
          return fetchWithRetry(variables, retries - 1);
        } else {
          setAiMessages?.((prev) => [...prev, `Network fail: ${err.message}`]);
          setIsAiHeaderOpen?.(true);
          return false;
        }
      }
    },
    [getNodeAndRelationships, setAiMessages, setIsAiHeaderOpen]
  );

  /**
   * The main function you call:
   *   fetchNodeAndRelationships({ id, name, depth=1, originNodeId })
   */
  const fetchNodeAndRelationships = useCallback(
    async ({ id, name, depth = 15, originNodeId = null }) => {
      // Build GraphQL variables
      const variables = { depth };
      if (id) variables.id = id;
      if (name) variables.name = name;

      if (!id && !name) {
        console.warn(
          "[useHandleGetNodeAndRelationships] no ID or name provided"
        );
        return false;
      }

      // If you want to store “originNodeId” in a ref, you could do it here
      // or just return the data and let the caller handle it.

      // Then do the query
      const success = await fetchWithRetry(variables, 2);
      return success;
    },
    [fetchWithRetry]
  );

  // Return the data, error, loading, plus our main fetch function
  return {
    fetchNodeAndRelationships,
    fetchData: data,    // Renamed here
    error,
    loading,
  };
}