import { useCallback, useRef, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_NODE_AND_RELATIONSHIPS } from "../../../../graphql/Graph/graph_queries";

export default function useHandleNodeEvents({
  userRole,
  setActionPanelOpen,
  setPanelOpenNode,
  setSelectedNodes,
  selectedNodesRef,
  panelOpenNodeRef,
  actionPanelOpenRef,
  originNodeIdRef,
  setAiMessages,
  setIsAiHeaderOpen,
  handleCreateRelationship,
  relationshipSourceId,
  setRelationshipSourceId,
  relationshipSourceRef,
  reBFSfromNode,
}) {
  const clickTimeout = useRef(null);

  // 1) Lazy query for expansions
  const [getNodeAndRelationships, { data: queryData, error: queryError }] =
    useLazyQuery(GET_NODE_AND_RELATIONSHIPS);

  /**
   * A) fetchWithRetry:
   *   - Takes Apollo variables and a “retriesRemaining” count
   *   - Tries up to N times if a network or top-level GraphQL error occurs
   *   - Inserts a 3-second delay between retries
   *   - Returns `true` if eventually successful, `false` if all attempts fail
   */
  const fetchWithRetry = useCallback(
    async (variables, retriesRemaining = 3) => {
      try {
        const result = await getNodeAndRelationships({ variables });

        // Check for GraphQL “errors” array
        if (result?.errors?.length) {
          console.warn("GraphQL returned errors:", result.errors);
          const firstErrMsg = result.errors[0]?.message || "Unknown error";

          if (retriesRemaining > 1) {
            // Indicate we're retrying
            setAiMessages((prev) => [
              ...prev,
              `Fetch expansions error: ${firstErrMsg}. Retrying in 3s...`,
            ]);

            // Wait 3 seconds before retrying
            await new Promise((resolve) => setTimeout(resolve, 3000));

            // Decrement and recurse
            return await fetchWithRetry(variables, retriesRemaining - 1);
          } else {
            // Final attempt => fail
            setAiMessages((prev) => [
              ...prev,
              `Failed after multiple attempts: ${firstErrMsg}`,
            ]);
            setIsAiHeaderOpen(true);
            return false;
          }
        }

        // If no “errors” => success
        return true;
      } catch (err) {
        // This catch triggers for network errors, etc.
        console.error("Error calling getNodeAndRelationships:", err);

        if (retriesRemaining > 1) {
          setAiMessages((prev) => [
            ...prev,
            `Fetch expansions network error: ${err.message}. Retrying in 3s...`,
          ]);

          // Wait 3 seconds before retrying
          await new Promise((resolve) => setTimeout(resolve, 3000));

          return await fetchWithRetry(variables, retriesRemaining - 1);
        } else {
          // Final attempt => fail
          setAiMessages((prev) => [
            ...prev,
            `Could not load node expansions (final fail): ${err.message}`,
          ]);
          setIsAiHeaderOpen(true);
          return false;
        }
      }
    },
    [getNodeAndRelationships, setAiMessages, setIsAiHeaderOpen]
  );

  /**
   * B) fetchNodeAndRelationships:
   *    - Prepares the `variables` object (id or name, depth, etc.)
   *    - Calls fetchWithRetry(variables, 3)
   *    - Returns `true` or `false` for success/failure
   */
  const fetchNodeAndRelationships = useCallback(
    async ({ id, name, depth = 1, originNodeId }) => {
      if (!originNodeId) {
        console.error("No originNodeId provided for fetchNodeAndRelationships");
        return false; // skip
      }
      originNodeIdRef.current = originNodeId;

      const variables = { depth };
      if (id && id.trim() !== "") {
        variables.id = id;
      } else if (name && name.trim() !== "") {
        variables.name = name;
      } else {
        console.error("No valid id or name provided for fetchNodeAndRelationships");
        return false; // skip
      }

      // Now call fetchWithRetry
      const success = await fetchWithRetry(variables, 3);
      return success;
    },
    [fetchWithRetry, originNodeIdRef]
  );

  // 3) Watch for queryError from Apollo (server returned an error)
  useEffect(() => {
    if (queryError) {
      console.error("Apollo queryError for getNodeAndRelationships:", queryError);
      setAiMessages((prev) => [
        ...prev,
        `Could not load expansions (Apollo error): ${queryError.message}`,
      ]);
      setIsAiHeaderOpen(true);
    }
  }, [queryError, setAiMessages, setIsAiHeaderOpen]);

  /**
   * handleNodeClick:
   *   - Relationship creation mode OR single-click expansions (with retry).
   */
  const handleNodeClick = useCallback(
    (node) => {
      // Relationship creation if needed
      if (
        relationshipSourceRef.current &&
        relationshipSourceRef.current !== node.id &&
        handleCreateRelationship
      ) {
        handleCreateRelationship({
          source: relationshipSourceRef.current,
          target: node.id,
          type: "RELATED_TO",
        });
        relationshipSourceRef.current = null;
        return;
      }

      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      // Single-click logic => 200ms delay to differentiate from double-click
      clickTimeout.current = setTimeout(async () => {
        if (!node.id) {
          // 1) Ephemeral node => open/close panel
          if (
            actionPanelOpenRef.current &&
            panelOpenNodeRef.current?.id === node.id
          ) {
            setActionPanelOpen(false);
            actionPanelOpenRef.current = false;
            setPanelOpenNode(null);
            panelOpenNodeRef.current = null;
          } else {
            setActionPanelOpen(true);
            actionPanelOpenRef.current = true;
            setPanelOpenNode(node);
            panelOpenNodeRef.current = node;
          }
        } else {
          // 2) Real node => if not expanded, fetch expansions
          if (!node.expanded) {
            const finalId = node.properties?.id || node.id;
            const success = await fetchNodeAndRelationships({
              id: finalId,
              depth: 1,
              originNodeId: finalId,
            });

            if (success) {
              node.alreadyClicked = true;
              node.expanded = true;
            }
          }

          // Optionally close the panel
          if (actionPanelOpenRef.current) {
            setActionPanelOpen(false);
            actionPanelOpenRef.current = false;
            setPanelOpenNode(null);
            panelOpenNodeRef.current = null;
          }
        }

        clickTimeout.current = null;
      }, 200);
    },
    [
      relationshipSourceRef,
      handleCreateRelationship,
      actionPanelOpenRef,
      panelOpenNodeRef,
      setActionPanelOpen,
      setPanelOpenNode,
      fetchNodeAndRelationships,
    ]
  );

  /**
   * handleNodeDoubleClick:
   *   - Cancels single-click.
   *   - Toggles selection, logs AI message, etc.
   */
  const handleNodeDoubleClick = useCallback(
    (node) => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }
      if (!node.id) {
        console.debug("Double-click on ephemeral node => no ID => skip");
        return;
      }

      const currentSelected = selectedNodesRef.current;
      const isSelected = currentSelected.some((sel) => sel.id === node.id);
      let newSelected;
      if (isSelected) {
        // Deselect => close panel
        setActionPanelOpen(false);
        actionPanelOpenRef.current = false;
        setPanelOpenNode(null);
        panelOpenNodeRef.current = null;
        newSelected = currentSelected.filter((n) => n.id !== node.id);
      } else {
        // Select => open panel
        setActionPanelOpen(true);
        actionPanelOpenRef.current = true;
        setPanelOpenNode(node);
        panelOpenNodeRef.current = node;
        newSelected = [...currentSelected, node];
      }
      setSelectedNodes(newSelected);

      // AI message
      const nodeName = node.name || "Unnamed";
      let nodeText = nodeName;
      if (node.description && node.description !== "No description") {
        nodeText += `: ${node.description}`;
      }
      if (userRole === "ADMIN") {
        nodeText += `\nID: ${node.id}, status: ${node.status || "N/A"}, privacy: ${node.privacy_status || "N/A"}`;
      }
      setAiMessages((prev) => [...prev, nodeText]);
      setIsAiHeaderOpen(true);
    },
    [
      clickTimeout,
      selectedNodesRef,
      actionPanelOpenRef,
      panelOpenNodeRef,
      setActionPanelOpen,
      setPanelOpenNode,
      setSelectedNodes,
      userRole,
      setAiMessages,
      setIsAiHeaderOpen,
    ]
  );

  return {
    handleNodeClick,
    handleNodeDoubleClick,
    queryData,
    queryError,
  };
}