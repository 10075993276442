// src/components/AethericAI/DevGraphNavigator/useLocalStorageGraphState.js

import { useEffect, useRef, useState } from "react";

/**
 * Manages:
 *  - selectedNodes (loaded/saved from localStorage)
 *  - pinnedPositions (loaded/saved from localStorage)
 *  - actionPanel state
 *  - rootNode & panelOpenNode
 */
export function useLocalStorageGraphState(user, initialNode, storageKey, initialNodeId) {
  // console.log("useLocalStorageGraphState", user);
  // console.log("useLocalStorageGraphState", user?.id, initialNode, storageKey);
  let selectedNodesKey = user?.id ? `selectedNodes-${user.id}` : null;
  let pinnedPositionsKey = user?.id ? `pinnedPositions-${user.id}` : null;
  let rootNodeKey = user?.id ? `rootNode-${user.id}` : null;
  if (storageKey) {
      // We'll just create sub-keys that incorporate storageKey
      // so each piece (selectedNodes, pinnedPositions, rootNode) uses that prefix.
      selectedNodesKey   = `${storageKey}-selectedNodes`;
      pinnedPositionsKey = `${storageKey}-pinnedPositions`;
      rootNodeKey        = `${storageKey}-rootNode`;
    }
  

  // selectedNodes
  const [selectedNodes, setSelectedNodes] = useState([]);
  const selectedNodesRef = useRef([]);
  const [didLoadFromStorage, setDidLoadFromStorage] = useState(false);

  // pinnedPositions
  const [pinnedPositions, setPinnedPositions] = useState(() => {
    if (!pinnedPositionsKey) return {};
    const saved = localStorage.getItem(pinnedPositionsKey);
    if (!saved) return {};
    try {
      return JSON.parse(saved);
    } catch (err) {
      console.error("Error parsing pinnedPositions:", err);
      return {};
    }
  });

  // action panel
  const [actionPanelOpen, setActionPanelOpen] = useState(false);
  const actionPanelOpenRef = useRef(false);

  const [rootNode, setRootNode] = useState(() => {
    if (rootNodeKey) {
      const savedRoot = localStorage.getItem(rootNodeKey);
      if (savedRoot) {
        try {
          return JSON.parse(savedRoot);
        } catch (err) {
          console.error("Error parsing rootNode from localStorage:", err);
        }
      }
    }
    if (initialNode) {
      return {
        // create a minimal node object
        id: initialNode.id,
        name: initialNode.name,
        privacyStatus: initialNode.privacyStatus,
        status: initialNode.status,      };
    } else {
      // fallback if initialNodeId isn't provided
      return {
        id: initialNodeId || "",
        name: "Aetheric AI (fallback)",
        privacyStatus: "PUBLIC",
        status: "VALIDATED",
        hopLevel: 0,
      };
    }
  });

    useEffect(() => {
        if (!initialNode) return; // no node from DB => do nothing
    
        // If our current rootNode is the fallback or the same ID, let's override
        // For example: if the fallback is "a0de03b3-b027-43e5-a411-0ccfe7820613"
        // or your “ethericAI” name, etc.
        // const isFallbackId = rootNode.id === "a0de03b3-b027-43e5-a411-0ccfe7820613";
        console.log("useLocalStorageGraphState rootNode.id", rootNode.id);
        console.log("useLocalStorageGraphState initialNode.id", initialNode.id);
        const isSameId = rootNode.id === initialNode.id;
    
        if (isSameId) {
          // Overwrite rootNode with the real DB node
          setRootNode({
            id: initialNode.id,
            name: initialNode.name || "Unknown Node",
            privacyStatus: initialNode.privacyStatus || "PUBLIC",
            status: initialNode.status || "VALIDATED",
            hopLevel: initialNode.hopLevel ?? 0,
          });
          console.log("useLocalStorageGraphState rootNode", rootNode);
        }
      }, [initialNode]);

  // node open in the panel
  const [panelOpenNode, setPanelOpenNode] = useState(null);
  const panelOpenNodeRef = useRef(null);

  /**
   * LOAD from localStorage
   */
  useEffect(() => {
    if (!selectedNodesKey) {
      console.warn("[useLocalStorageGraphState] No user id => skip localStorage for selectedNodes");
      return;
    }
    const saved = localStorage.getItem(selectedNodesKey);
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        if (Array.isArray(parsed)) {
          setSelectedNodes(parsed);
        }
      } catch (err) {
        console.error("Error parsing selectedNodes from localStorage:", err);
      }
    }
    setDidLoadFromStorage(true);
  }, [selectedNodesKey]);

  /**
   * SAVE to localStorage whenever selectedNodes changes
   */
  useEffect(() => {
    if (!didLoadFromStorage) return;
    if (selectedNodesKey) {
      localStorage.setItem(selectedNodesKey, JSON.stringify(selectedNodes));
    }
  }, [didLoadFromStorage, selectedNodes, selectedNodesKey]);

  /**
   * Keep a ref
   */
  useEffect(() => {
    selectedNodesRef.current = selectedNodes;
  }, [selectedNodes]);

  /**
   * pinnedPositions => save whenever changed
   */
  useEffect(() => {
    if (!pinnedPositionsKey) return;
    localStorage.setItem(pinnedPositionsKey, JSON.stringify(pinnedPositions));
  }, [pinnedPositions, pinnedPositionsKey]);

  // Then a useEffect to SAVE rootNode whenever it changes
  useEffect(() => {
    if (!rootNodeKey) return;
    localStorage.setItem(rootNodeKey, JSON.stringify(rootNode));
  }, [rootNode, rootNodeKey]);

  /**
   * actionPanelOpen => keep ref
   */
  useEffect(() => {
    actionPanelOpenRef.current = actionPanelOpen;
  }, [actionPanelOpen]);

  /**
   * Provide a small helper if you want to change root easily
   */
  function handleSetRoot(node) {
    if (node?.id) {
      setRootNode({ ...node, id: String(node.id) });
    } else if (node) {
      setRootNode({ id: "", name: node.name || "Unnamed Node" });
    } else {
      setRootNode({ id: "", name: "Unknown Node" });
    }
  }

  return {
    // pinned
    pinnedPositions,
    setPinnedPositions,

    // selected
    selectedNodes,
    setSelectedNodes,
    selectedNodesRef,

    // panel
    actionPanelOpen,
    setActionPanelOpen,
    actionPanelOpenRef,

    // root
    rootNode,
    setRootNode,

    // open node in panel
    panelOpenNode,
    setPanelOpenNode,
    panelOpenNodeRef,

    // localStorage keys
    selectedNodesKey,
    pinnedPositionsKey,
    rootNodeKey,

    handleSetRoot,
  };
}