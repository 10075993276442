// src/components/AethericAI/AethericAI.js

import React from 'react';
import NewGraphNavigator from './GraphNavigator/NewGraphNavigator';
import logo from '../../assets/images/AethericAI.png';

const AethericAI = () => {
  return (
    <div className="flex justify-center items-center bg-black flex-grow">
      <NewGraphNavigator
        initialNodeId="a0de03b3-b027-43e5-a411-0ccfe7820613"
        graphId="Graph-AethericAI" 
      />
    </div>
  );
};

export default AethericAI;
