// src/components/AethericAI/DevGraphNavigator/useGraphData.js

import { useEffect, useRef, useState, useCallback } from "react";

// ======================
// 1) BFS-based function to mark hop levels
// ======================
function markHopLevels({ nodes, links }, centerNodeId, maxDepth = 2) {
  const queue = [{ id: centerNodeId, level: 0 }];
  const visited = new Set([centerNodeId]);

  while (queue.length > 0) {
    const { id, level } = queue.shift();
    const nodeObj = nodes.find((n) => n.id === id);
    if (nodeObj) {
      // Only set hopLevel if we haven't set it before,
      // or if we're giving it a smaller (closer) hopLevel
      if (nodeObj.hopLevel == null || level < nodeObj.hopLevel) {
        nodeObj.hopLevel = level;
      }
    }
    if (level >= maxDepth) continue;

    // All neighbors
    const neighbors = links
      .filter((l) => l.source.id === id || l.target.id === id)
      .map((l) => (l.source.id === id ? l.target.id : l.source.id));

    for (const neighborId of neighbors) {
      if (!visited.has(neighborId)) {
        visited.add(neighborId);
        queue.push({ id: neighborId, level: level + 1 });
      }
    }
  }
}


// function markHopLevelsFromNode(graph, centerNodeId, maxDepth = 2) {
//   // Example BFS code: set all to hopLevel = null, then BFS from centerNodeId
//   graph.nodes.forEach((n) => { n.hopLevel = null; });

//   const queue = [{ id: centerNodeId, level: 0 }];
//   const visited = new Set([centerNodeId]);

//   while (queue.length) {
//     const { id, level } = queue.shift();
//     const nodeObj = graph.nodes.find((n) => n.id === id);
//     if (nodeObj) {
//       nodeObj.hopLevel = level;
//     }
//     if (level >= maxDepth) continue;

//     const neighbors = graph.links
//       .filter((l) => l.source.id === id || l.target.id === id)
//       .map((l) => (l.source.id === id ? l.target.id : l.source.id));

//     for (const neighborId of neighbors) {
//       if (!visited.has(neighborId)) {
//         visited.add(neighborId);
//         queue.push({ id: neighborId, level: level + 1 });
//       }
//     }
//   }
// }

function markHopLevelsFromNode(graph, centerNodeId, maxDepth = 2) {
  // Instead of mutating graph.nodes directly,
  // we do a small copy of each node, so we can safely assign hopLevel.
  const copiedNodes = graph.nodes.map((n) => ({ ...n }));

  // Then run BFS on those copies
  const queue = [{ id: centerNodeId, level: 0 }];
  const visited = new Set([centerNodeId]);

  // set hopLevel to null initially
  copiedNodes.forEach((node) => {
    node.hopLevel = null;
  });

  // BFS
  while (queue.length > 0) {
    const { id, level } = queue.shift();
    const nodeObj = copiedNodes.find((n) => n.id === id);
    if (nodeObj && (nodeObj.hopLevel === null || level < nodeObj.hopLevel)) {
      nodeObj.hopLevel = level;
    }

    if (level < maxDepth) {
      const neighbors = graph.links
        .filter((l) => l.source.id === id || l.target.id === id)
        .map((l) => (l.source.id === id ? l.target.id : l.source.id));
      for (const nbr of neighbors) {
        if (!visited.has(nbr)) {
          visited.add(nbr);
          queue.push({ id: nbr, level: level + 1 });
        }
      }
    }
  }

  // Return a new “graph” object with the updated nodes
  return {
    nodes: copiedNodes,
    links: graph.links, // or copy links if needed
  };
}

// ======================
// 2) Rebind link references from string => node objects
// ======================
function rebindLinkReferences(nodes, links) {
  // Build a map of nodeId => nodeObject
  const nodeMap = new Map(nodes.map((n) => [n.id, n]));

  const rebinded = [];
  for (const link of links) {
    // Convert source/target to IDs if they're objects:
    const srcId = typeof link.source === "object" ? link.source.id : link.source;
    const tgtId = typeof link.target === "object" ? link.target.id : link.target;

    // Now pick up the new node objects
    const source = nodeMap.get(srcId);
    const target = nodeMap.get(tgtId);

    // If either is missing, skip this link
    if (!source || !target) continue;

    // Re-create the link with the new references
    rebinded.push({
      ...link,
      source,
      target,
    });
  }
  return rebinded;
}

/**
 * placeNodesRecursively:
 *   - Performs a DFS from `currentNodeId`.
 *   - If a neighbor is new (in newNodeIds) and unvisited, place it around the current node.
 *   - Then recurse from that neighbor to position its new neighbors, etc.
 *   - Old nodes will not be moved, but can serve as “bridges” to newly added nodes.
 */
function placeNodesRecursively(graph, currentNodeId, newNodeIds, visited) {
  // If we have visited this node before, skip
  if (visited.has(currentNodeId)) return;
  visited.add(currentNodeId);

  const currentNode = graph.nodes.find((n) => n.id === currentNodeId);
  if (!currentNode) return;

  // 1) Find neighbors
  const neighborLinks = graph.links.filter(
    (l) => l.source.id === currentNodeId || l.target.id === currentNodeId
  );
  const neighbors = neighborLinks.map((l) =>
    l.source.id === currentNodeId ? l.target : l.source
  );

  // 2) Separate new neighbors vs old neighbors
  const newNeighbors = neighbors.filter(
    (nbr) => newNodeIds.has(nbr.id) && !visited.has(nbr.id)
  );

  // 3) Place the new neighbors around currentNode in a circle
  if (newNeighbors.length > 0) {
    const radius = 150; // choose your desired radius
    newNeighbors.forEach((nbr, i) => {
      const angle = (2 * Math.PI * i) / newNeighbors.length;
      nbr.x = currentNode.x + radius * Math.cos(angle);
      nbr.y = currentNode.y + radius * Math.sin(angle);
    });
  }

  // 4) Recurse on **all** neighbors (new or old), because an old neighbor
  //    might lead to other new neighbors deeper down the chain.
  neighbors.forEach((nbr) => {
    if (!visited.has(nbr.id)) {
      placeNodesRecursively(graph, nbr.id, newNodeIds, visited);
    }
  });
}

/**
 * useGraphData:
 *   - Loads from localStorage or uses a single initial node fallback
 *   - insertNodesAndLinks (with BFS + optional ring-position)
 *   - updateNodesAndLinks
 *   - deleteNodesAndLinks
 *   - resetGraphData
 */
export const useGraphData = ({ initialNode, data, originNodeIdRef, user,storageKey }) => {

  // ======================
  // 1) Initialize graphData from localStorage or fallback
  // ======================
  const [graphData, setGraphData] = useState(() => {
    const saved = localStorage.getItem(storageKey);
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        if (parsed?.nodes && parsed?.links) {
          const rebindLinks = rebindLinkReferences(parsed.nodes, parsed.links);
          return { nodes: parsed.nodes, links: rebindLinks };
        }
      } catch (err) {
        console.error("Error parsing localStorage:", err);
      }
    }
    // Fallback => single initial node
    return {
      nodes: [
        {
          id: String(initialNode.id),
          name: initialNode.name,
          x: 400,
          y: 300,
          hopLevel: 0,
          privacyStatus: "PUBLIC",
          status: "VALIDATED",
        },
      ],
      links: [],
    };
  });

  // Keep a ref version of the graph for direct reading
  const graphDataRef = useRef(graphData);

  // ======================
  // 2) resetGraphData => remove localStorage, restore a single initial node
  // ======================
  const resetGraphData = useCallback(() => {
    localStorage.removeItem(storageKey);
    setGraphData({
      nodes: [
        {
          id: String(initialNode.id),
          name: initialNode.name,
          x: 400,
          y: 300,
          hopLevel: 0,
        },
      ],
      links: [],
    });
  }, [initialNode, storageKey]);

  // ======================
  // 3) insertNodesAndLinks:
  //    - BFS => markHopLevels
  //    - ring-position newly fetched nodes with hopLevel <= 1
  // ======================
  const insertNodesAndLinks = useCallback(
    (newNodes = [], newLinks = [], originNodeId) => {
      setGraphData((prevData) => {
        const existingNodes = [...prevData.nodes];
        const existingLinks = [...prevData.links];
  
        // 1) Merge or update existing nodes
        newNodes.forEach((newNode) => {
          const existingIndex = existingNodes.findIndex(
            (ex) => ex.id === newNode.id
          );
          if (existingIndex >= 0) {
            // Overwrite fields on the existing node
            existingNodes[existingIndex] = {
              ...existingNodes[existingIndex],
              ...newNode,
            };
          } else {
            // It's genuinely new
            existingNodes.push(newNode);
          }
        });
  
        // 2) Merge or update existing links
        newLinks.forEach((newLink) => {
          const existingLinkIndex = existingLinks.findIndex(
            (ex) => ex.id === newLink.id
          );
          if (existingLinkIndex >= 0) {
            existingLinks[existingLinkIndex] = {
              ...existingLinks[existingLinkIndex],
              ...newLink,
            };
          } else {
            existingLinks.push(newLink);
          }
        });
  
        // 3) Always rebind link references to the *latest* node objects
        const rebindedLinks = rebindLinkReferences(existingNodes, existingLinks);
  
        // 4) Optionally place newly added nodes around the origin node
        if (originNodeId) {
          const newGraph = { nodes: existingNodes, links: rebindedLinks };
          // Example BFS placement if you like:
          const newNodeIds = new Set(newNodes.map((n) => n.id));
          placeNodesRecursively(newGraph, originNodeId, newNodeIds, new Set());
          return newGraph;
        }
  
        return { nodes: existingNodes, links: rebindedLinks };
      });
    },
    []
  );

  // ======================
  // 4) updateNodesAndLinks
  // ======================
  const updateNodesAndLinks = useCallback(
    (updatedNodes = [], updatedLinks = []) => {
      setGraphData((prevData) => {
        const nodeMap = Object.fromEntries(prevData.nodes.map((n) => [n.id, n]));
        const linkMap = Object.fromEntries(prevData.links.map((l) => [l.id, l]));

        // Merge updated nodes
        updatedNodes.forEach((upd) => {
          if (nodeMap[upd.id]) {
            Object.assign(nodeMap[upd.id], upd);
          }
        });
        // Merge updated links
        updatedLinks.forEach((upd) => {
          if (linkMap[upd.id]) {
            Object.assign(linkMap[upd.id], upd);
          }
        });

        const mergedNodes = Object.values(nodeMap);
        const mergedLinks = Object.values(linkMap);
        const rebindedLinks = rebindLinkReferences(mergedNodes, mergedLinks);
        return { nodes: mergedNodes, links: rebindedLinks };
      });
    },
    []
  );

  // ======================
  // 5) deleteNodesAndLinks
  // ======================
  const deleteNodesAndLinks = useCallback(
    (nodeIdsToDelete = [], relationshipIdsToDelete = []) => {
      setGraphData((prevData) => {
        // Filter out the “to-delete” node IDs
        const finalNodes = prevData.nodes.filter(
          (n) => !nodeIdsToDelete.includes(n.id)
        );
        // Filter out links referencing deleted nodes or matching relationshipIds
        const finalLinks = prevData.links.filter((l) => {
          if (relationshipIdsToDelete.includes(l.id)) return false;
          if (nodeIdsToDelete.includes(l.source.id)) return false;
          if (nodeIdsToDelete.includes(l.target.id)) return false;
          return true;
        });
        return { nodes: finalNodes, links: finalLinks };
      });
    },
    []
  );

  // Optionally use `data` from GraphQL if needed
  useEffect(() => {
    if (data?.getNodeAndRelationships) {
      // Example:
      // insertNodesAndLinks(
      //   data.getNodeAndRelationships.nodes,
      //   data.getNodeAndRelationships.relationships,
      //   originNodeIdRef?.current
      // );
    }
  }, [data]);

  // Keep a ref copy
  useEffect(() => {
    graphDataRef.current = graphData;
  }, [graphData]);

  // ======================
  // 6) Persist to localStorage on every update
  // ======================
  useEffect(() => {
    const savedNodes = graphData.nodes.map((n) => ({ ...n }));
    const savedLinks = graphData.links.map((l) => ({
      ...l,
      source: l.source?.id || l.source,
      target: l.target?.id || l.target,
    }));
    const payload = { nodes: savedNodes, links: savedLinks };
    localStorage.setItem(storageKey, JSON.stringify(payload));
  }, [graphData, storageKey]);

  const reBFSfromNode = useCallback((nodeId, maxDepth = 2) => {
    setGraphData((prevGraph) => {
      // 1) Shallow copy => so we don’t mutate prevGraph directly
      const newGraph = {
        nodes: [...prevGraph.nodes],
        links: [...prevGraph.links],
      };

      // 2) BFS from that node => sets hopLevel = 0, neighbors=1, etc.
      // markHopLevelsFromNode(newGraph, nodeId, maxDepth);

      // 3) Return new graph => triggers re-render
      return newGraph;
    });
  }, []);


  // Return the methods + data
  return {
    graphData,
    insertNodesAndLinks,
    updateNodesAndLinks,
    deleteNodesAndLinks,
    resetGraphData,
    reBFSfromNode,
  };
};